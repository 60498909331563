import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ChatArea } from "src/components/ChatArea";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  BackgroundImage,
  Image,
  LoadingOverlay,
  Text,
  UnstyledButton,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { Navigate, useNavigate } from "react-router-dom";
import {
  handleUpdateAppSettings,
  handleUserUpdate,
  handleWalkthroughUpdate,
} from "src/redux/redux-store";
import { ChatStyledContainerInset, StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { Notifications } from "@mantine/notifications";
import ChatInput from "src/components/common/Inputs/ChatMessageInput";
import { useChat } from "src/hooks/useChat";
import { NotificationTypes, PubSubNotificationTypes } from "src/enums/Notifications";
import useResponsive from "src/hooks/useResponsive";
import { sendFailureNotification } from "src/utils/notifications";
import Icon from "src/components/common/Icons/Icons/Icon";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import ParentChatSidebar from "src/components/Sidebars/Parent/ParentSIdebarHome";
import { Disclaimer } from "src/components/Chat/Disclaimer";
import { useFileUpload } from "src/hooks/useFileUpload";
import { CustomButton } from "src/components/common/Buttons/CustomButton";
import { usePayments } from "src/hooks/usePayment";
import ReactJoyride, { ACTIONS, CallBackProps, EVENTS, ORIGIN, Status, STATUS, Step, StepMerged, StepProps } from "react-joyride";
import { ParentWalkthrough } from "src/components/Walkthroughs/parent";
import { parentRoutes } from "src/navigators/routes/ParentRoutes";
import TrialExpiryNoticeModal from "src/components/Modals/TrialExpiryNotice";
import IOSSafeChatContainer from "src/components/Layout/IOSSafeChatContainer";


const useStyles = createStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",

  },
  textAreaAndDisclaimer: {
    alignSelf: "center",
    justifySelf: "center",

    '@media (min-width: 768px)': {

    },
  }
}));

const ParentDashboard = React.memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const user = useAppSelector(state => state.user)
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const sessionId = useAppSelector((state) => state.chat.selectedSession)
  const [uploadListener, setUploadListener] = useState(false)
  const [trialExpiryNoticeOpen, setTrialExpiryNoticeOpen] = useState(false)
  const [removeFile, setRemoveFile] = useState(false)
  const [confirmSend, setConfirmSend] = useState(false)
  const { newSession } = useChat()
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const sessionsLoading = useAppSelector((state) => state.chat.sessionsLoading)
  const { uploadStatus, uploadedFile, setUploadedFile, uploadFile, client, setUploadStatus } = useFileUpload();
  const {
    inputMessage,
    setInputMessage,
    sendMessage,
    getMessages,
    messages,
    question_mode,
    chatLoading,
    streaming,
    suggestQuestion,
    triggerMessageForFileUpload,
    getSessions,
    triggerMessageForFileUploadError
  } = useChat();
  const {userPlan, getCurrentPlan} = usePayments()

  useEffect(() => {
    if (sessionId.id) {
      getMessages(sessionId.id);
    }
  }, [sessionId]);

  const handleSend = async (message: string) => {
    let idSession = sessionId;
    
    if (uploadedFile) {
      try {
        triggerMessageForFileUpload(message, uploadedFile);
        await uploadFile(uploadedFile, idSession.id);
        // File upload successful
      } catch (error) {
        console.error('Error during file upload:', error);
        triggerMessageForFileUploadError(message);
        setUploadStatus('idle');
        setUploadedFile(null);
        return; // Exit the function if file upload failed
      }
    }
  
    // Proceed with sending the message
    try {
      await sendMessage(message, !!uploadedFile);
      setUploadedFile(null);
      setInputMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      sendFailureNotification(NotificationTypes.chatSession, "Failed to send message. Please try again.");
    } finally {
      setUploadStatus('idle');
    }
  };

  useEffect(() => {
    if (client) {
      const handleServerMessage = (data: any) => {
        if (data.message.data) {
          const message = JSON.parse(data.message.data.toString());
          if (message.event_type === PubSubNotificationTypes.appInfoUpdate) {
            dispatch(handleUpdateAppSettings());
          }
        }
      };

      client.on("server-message", handleServerMessage);
      return () => {
        client.off("server-message", handleServerMessage);
      };
    }
  }, [client, dispatch]);


  useEffect(() => {
    const initializeComponent = async () => {
      // await checkRole();
   
      getAllData();
      getSessions();
    };

    initializeComponent();
  }, []);

  useEffect(() => {
    if(user && userPlan){
      if(!user.trial_expired && !userPlan?.has_active_subscription) {
        setTrialExpiryNoticeOpen(user.trial_days ? user.trial_days <= 3 : false)
      }
    }
  }, [userPlan, user])

  const checkFreeTrial = () => {
    // if (user.trial_expired && !user.paid) {
    //   navigate(userRoutes.PAYMENT)
    // }
  }


  const getAllData = useCallback(() => {
    dispatch(handleUserUpdate());
    dispatch(handleUpdateAppSettings());
    getCurrentPlan();
    checkFreeTrial();
    // getAzureToken()
  }, [dispatch]);


  // const handleStopStream = useCallback(() => {
  //   dispatch(stopStream(true));
  // }, [dispatch]);


  // let heightStyle;
  // if (isMobile) {
  //   // Is tablet or mobile (Max width 1024px)
  //   heightStyle = 'calc((var(--vh, 1vh) * 100) - 40px)';
  // } else {
  //   // Desktop (Min width 1024px)
  //   heightStyle = 'calc((var(--vh, 1vh) * 100) - 70px)';
  // }

  const header = (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row items-center gap-2">
        <Icon name="chat" className="size-6 md:size-8" />
        <Text>{sessionId.title}</Text>
      </div>
      <CustomButton
        onClick={newSession}
        width="w-40"
        className="new-chat-step text-base font-semibold"
        leftIcon="edit"

        size="sm"
      >
        New Chat
      </CustomButton>
    </div>
  )


  return (
    <DynamicLayout
      heading={header}
      rightContent={<UnstyledButton
        className="bg-secondary w-6 h-6 rounded-md p-[5px]"
        onClick={newSession}
      >
      <img src="/icons/newChat.png" alt="edit" />
      </UnstyledButton>}
      SidebarComponent={ParentChatSidebar}
    >
      <ParentWalkthrough startWalkthrough={!user.walkthrough_completed && !isMobile} onCloseWalkthrough={() => {
        dispatch(handleWalkthroughUpdate())
      }} />

      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }} className="flex flex-col h-[calc(100%-55px)] md:h-[calc(100%-70px)]">
        <ChatStyledContainerInset theme={{ backgroundColor: backgroundColor }} className="flex-1 flex flex-col min-h-0">
          <TrialExpiryNoticeModal
            isOpen={trialExpiryNoticeOpen}
            onClose={() => setTrialExpiryNoticeOpen(false)}
            onUpgrade={() => navigate(parentRoutes.PAYMENT)}
          />
          <BackgroundImage src="/images/doodles.png" className="flex-1 flex flex-col min-h-0">
            <Notifications
              position={isMobile ? "top-center" : "bottom-right"}
              limit={isMobile ? 1 : 5}
            />
            <LoadingOverlay overlayBlur={10} visible={chatLoading || sessionsLoading} />
            
            {/* Main chat container */}
            <div className="flex-1 flex flex-col min-h-0 w-full lg:w-[700px] xl:w-[900px] py-0 md:py-2 mx-auto">
              {/* Scrollable chat area */}
              <div className="flex-1 relative min-h-0">
                <ChatArea 
                  sendMessage={sendMessage} 
                  username={user.name} 
                  messages={messages} 
                  streaming={streaming} 
                />
              </div>
              
              {/* Fixed input area */}
              <div className="flex-shrink-0 pb-safe px-2 pt-2">
                <ChatInput
                  handleUpgrade={() => navigate(parentRoutes.PAYMENT)}
                  access={userPlan?.has_active_subscription ? "Premium" : user.trial_expired ? "Free" : "Premium"}
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                  onSend={handleSend}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  uploadStatus={uploadStatus}
                  streaming={streaming}
                  disabled={streaming || !inputMessage}
                />
                <Disclaimer text={appSettings?.parent_disclaimers} />
              </div>
            </div>
          </BackgroundImage>
        </ChatStyledContainerInset>
      </StyledContainer>
    </DynamicLayout>
  );
});

export default ParentDashboard;
