import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ChatArea } from "src/components/ChatArea";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  BackgroundImage,
  Button,
  Card,
  Center,
  Container,
  LoadingOverlay,
  Text,
  Transition,
  UnstyledButton,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { UserRole } from "src/enums/User";
import { Navigate, useNavigate } from "react-router-dom";
import { getRole } from "src/utils/storage";
import {
  clearChatState,
  handleUpdateAppSettings,
  handleUserUpdate,
  handleWalkthroughUpdate,
  resetUser,
  stopStream,
  updateAzureToken,
} from "src/redux/redux-store";
import { ChatStyledContainerInset, StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications, showNotification } from "@mantine/notifications";
import ChatInput from "src/components/common/Inputs/ChatMessageInput";
import { useChat } from "src/hooks/useChat";
import { useNotifications } from "src/hooks/useNotifications";
import { NotificationTypes, PubSubNotificationTypes } from "src/enums/Notifications";
import useResponsive from "src/hooks/useResponsive";
import { userRoutes } from "src/navigators/routes/user";
import { sendFailureNotification, sendFeedbackNotification } from "src/utils/notifications";
import Icon from "src/components/common/Icons/Icons/Icon";
import { colors } from "@mui/material";
import { Disclaimer } from "src/components/Chat/Disclaimer";
import { useFileUpload } from "src/hooks/useFileUpload";
import { ChildWalkthrough } from "src/components/Walkthroughs/child";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { authRoutes } from "src/navigators/routes/auth";
import { useAuth } from "src/hooks/useAuth";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import { CustomButton } from "src/components/common/Buttons/CustomButton";
import TopNavbar from "src/components/TopNavbar";
import Sidebar from "src/components/SideBar";

const Dashboard = React.memo(() => {
  const dispatch = useAppDispatch();
  const theme = useMantineTheme();
  const navigate = useNavigate()
  const sessionId = useAppSelector(state => state.chat.selectedSession)
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const [loading, setLoading] = useState(true)
  const { newSession } = useChat()
  const user = useAppSelector((state) => state.user);
  const sessionsLoading = useAppSelector((state) => state.chat.sessionsLoading)
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const { uploadStatus, uploadedFile, setUploadedFile, uploadFile, client, setUploadStatus } = useFileUpload();
  const {
    inputMessage,
    setInputMessage,
    sendMessage,
    getMessages,
    messages,
    question_mode,
    chatLoading,
    streaming,
    suggestQuestion,
    triggerMessageForFileUpload,
    triggerMessageForFileUploadError,
    getSessions,
  } = useChat();
  const {logout} = useAuth()

  useEffect(() => {
    if (sessionId.id) {
      getMessages(sessionId.id);
    }
  }, [sessionId]);

  const handleSend = async (message: string) => {
    let idSession = sessionId;
    
    if (uploadedFile) {
      try {
        triggerMessageForFileUpload(message, uploadedFile);
        await uploadFile(uploadedFile, idSession.id);
        // File upload successful
      } catch (error) {
        console.error('Error during file upload:', error);
        triggerMessageForFileUploadError(message);
        setUploadStatus('idle');
        setUploadedFile(null);
        return; // Exit the function if file upload failed
      }
    }
  
    // Proceed with sending the message
    try {
      await sendMessage(message, !!uploadedFile);
      setUploadedFile(null);
      setInputMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      sendFailureNotification(NotificationTypes.chatSession, "Failed to send message. Please try again.");
    } finally {
      setUploadStatus('idle');
    }
  };

  // Effect for handling app settings updates
  useEffect(() => {
    if (client) {
      const handleServerMessage = (data: any) => {
        if (data.message.data) {
          const message = JSON.parse(data.message.data.toString());
          if (message.event_type === PubSubNotificationTypes.appInfoUpdate) {
            dispatch(handleUpdateAppSettings());
          }
        }
      };

      client.on("server-message", handleServerMessage);
      return () => {
        client.off("server-message", handleServerMessage);
      };
    }
  }, [client, dispatch]);

  useEffect(() => {
    const initializeComponent = async () => {
      // await checkRole();
      getAllData();
      getSessions();
    };

    initializeComponent();
  }, []);


  const getAllData = useCallback(async () => {
    dispatch(handleUserUpdate())
    dispatch(handleUpdateAppSettings());
  }, [dispatch]);


  const handleSuggestQuestion = useCallback((option: boolean) => {
    if (option) {
      suggestQuestion(true, sessionId.id)
    } else {
      suggestQuestion(false, sessionId.id)
    }
  }, [sessionId])

  const header = (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row items-center gap-2">
        <Icon name="chat" className="size-6 md:size-8" />
        <Text>{sessionId.title}</Text>
      </div>
      <CustomButton
        // onClick={newSession}
        width="w-40"
        className="new-chat-step text-base font-semibold"
        leftIcon="edit"

        size="sm"
      >
        New Chat
      </CustomButton>
    </div>
  )


  return (
    <DynamicLayout
    heading={header}
    rightContent={<UnstyledButton
      className="bg-secondary w-6 h-6 rounded-md p-[5px]"
      onClick={newSession}
    >
    <img src="/icons/newChat.png" alt="edit" />
    </UnstyledButton>}
    SidebarComponent={Sidebar}
  >
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }} className="flex flex-col h-[calc(100%-55px)] md:h-[calc(100%-70px)]">
      <ChatStyledContainerInset theme={{ backgroundColor: backgroundColor }} className="flex-1 flex flex-col min-h-0">
        <BackgroundImage className="flex-1 flex flex-col min-h-0" src="/images/doodles.png">
          <Notifications
            position={isMobile ? "top-center" : "bottom-right"}
            limit={isMobile ? 1 : 5}
          />
          <ChildWalkthrough startWalkthrough={!user.walkthrough_completed && !isMobile} onCloseWalkthrough={() => {
            dispatch(handleWalkthroughUpdate())
          }}  />
          
          {/* <ErrorMessage error={error ? error : ""} handleClose={dismissError} />  */}
          <LoadingOverlay overlayBlur={10} visible={chatLoading || sessionsLoading} />
          <div
            className={`flex-1 flex flex-col min-h-0 w-full lg:w-[700px] xl:w-[900px] py-0 md:py-2 mx-auto`}>

          <div className="flex-1 relative min-h-0">  
            <ChatArea sendMessage={sendMessage} username={user.name} messages={messages} streaming={streaming} />
            </div>

            <div className="flex items-center px-0 md:px-4 mb-2">
              <div className="w-[100%]">
                <Transition mounted={question_mode} transition="fade" duration={500} timingFunction="ease">
                  {(styles) => <Card style={styles}>
                    <div className="flex flex-row gap-4 items-center justify-center" >
                      {/* <Text>Do you want to test your knowledge?</Text> */}
                      <Card 
                        className="bg-primary"
                        w={"100%"}
                        onClick={() => { handleSuggestQuestion(false) }}
                        style={{ cursor: "pointer" }}
                        withBorder py={4}>
                        <div className="flex flex-row gap-1 justify-between items-center">
                          <Text style={{ color: 'white' }} size={"sm"}>Continue asking more questions</Text>
                          <Icon color="white" name="question" size="1.1rem" />
                        </div></Card>
                      <Card
                        w={"100%"}
                        onClick={() => { handleSuggestQuestion(true) }}
                        style={{ cursor: "pointer" }} bg={COLORS.primary}
                        withBorder py={4}>
                        <div className="flex flex-row gap-1 justify-between items-center"><Text size={"sm"}>Test my knowledge</Text><Icon name="qa" size="1.1rem" /></div></Card>

                    </div></Card>}
                </Transition>
                <div className="flex-shrink-0 pb-safe px-2 pt-2">
                <ChatInput
                  handleUpgrade={() => sendFeedbackNotification(NotificationTypes.accountUpgrade, "Ask your parent to upgrade your plan.")}
                  inputMessage={inputMessage}
                  access={user.trial_expired ? "Free" : "Premium"}
                  setInputMessage={setInputMessage}
                  onSend={handleSend}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  uploadStatus={uploadStatus}
                  streaming={streaming}
                  disabled={streaming || !inputMessage || question_mode}
                />
                </div>
                <Disclaimer text={appSettings?.child_disclaimers} />
              </div></div>
          </div>
        </BackgroundImage>
      </ChatStyledContainerInset>
    </StyledContainer>
    </DynamicLayout>
  );
});

export default Dashboard;
