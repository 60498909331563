import React, { ReactNode, useEffect, useState } from 'react';
import { ActionIcon, Menu, Text } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';
import { HeaderContainer } from '../common/Containers/HeaderContainer';
import ParentHeader from '../Navbar/ParentHeader';
import useViewportHeight from 'src/hooks/useViewPortHeight';
import { useAppSelector } from 'src/redux/redux-store/hooks';

interface LayoutProps {
  SidebarComponent: React.ComponentType<any>;
  children: ReactNode;
  heading: ReactNode;
  rightContent?: ReactNode;
}

const DynamicLayout: React.FC<LayoutProps> = ({ SidebarComponent, children, heading, rightContent }) => {
  // useViewportHeight()
  const [isMobile, setIsMobile] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const chatSession = useAppSelector(state => state.chat.selectedSession)

  useEffect(() => {
    setSidebarOpen(false)
  }, [chatSession.id])

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < 768);
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile]);

  const openSideBar = () => {
    setSidebarOpen(true)
  }

  return (
      <div className="h-svh overflow-hidden flex flex-row">
        {/* Sidebar */}
        <aside
          className={`
            fixed inset-y-0 left-0 z-50 w-[320px] bg-gray-200 transform transition-transform duration-300 ease-in-out
            ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
            md:relative md:translate-x-0
          `}
        >
          <ActionIcon className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 md:hidden" onClick={() => setSidebarOpen(false)}>
            <Icon name="cross"/>
          </ActionIcon>
          <SidebarComponent/>
        </aside>

        {/* Main Content Area */}
        <div className="flex flex-col flex-1 w-full md:w-[calc(100%-320px)]">
          {/* Header - Always visible */}
          <ParentHeader 
            isMobile={isMobile} 
            renderHeading={heading} 
            rightContent={rightContent} 
            sideBarOpened={sidebarOpen} 
            openSideBar={openSideBar} 
          />
          
          {/* Main Content */}
          <div className="flex flex-1 justify-center overflow-hidden">
            {children}
          </div>
        </div>

        {/* Overlay */}
        {sidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
            onClick={() => setSidebarOpen(false)}
          ></div>
        )}
      </div>
  );
};

export default DynamicLayout;
