import styled from "@emotion/styled";

interface ThemeProps {
  theme: {
    backgroundColor: string;
  };
}

// Assuming the parent container is also styled using styled-components
export const NavBarParentContainer = styled.div<ThemeProps>`
  background-color: ${(props) => props.theme.backgroundColor || 'white'};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center; // This centers all child elements horizontally
  width: 100%; // Ensure full width if necessary
`;

export const NavBarContainer = styled.div<ThemeProps>`
background-color: ${(props) => props.theme.backgroundColor || 'white'};
width: 99%;
height: 90%;
padding-right: 1rem;
padding-left: 1rem;
display: flex;
align-items: center;
border-width: 1px;
border-radius: 0.45rem 0.45rem 0.45rem 0.45rem; 
// Consistent with the outer's border-radius in larger screens  

@media (max-width: 768px) {
  width: 100%;
  height: 100%;
  padding-top: 0.25rem;
  border-radius: 0 0 0 0; 
  // Additional styling for larger screens can be placed here if needed
}
`;